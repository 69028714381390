
.loaders{
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  max-width: 450px;
  background: #000000b8;
  height: 100%;
  left: 0;
  right: 0;
  z-index: 1;
  margin: auto;
}
.my-loader{
 position: relative;
 z-index: 5555;
}
